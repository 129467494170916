<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>附加积分计算</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span>起止时间：</span>
          <el-date-picker class="picker_box" v-model="startDate" type="date" placeholder="选择日期">
          </el-date-picker>
          -
          <el-date-picker class="picker_box" v-model="endDate" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="input_box"><span>所在赛区：</span><el-input placeholder="请输入所在赛区" style="width: 180px"></el-input></div>
        <div class="input_box"><span>打卡类型：</span>
          <el-select v-model="value" multiple filterable allow-create default-first-option placeholder="请选择打卡类型">
            <el-option v-for="item in dkTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box"><span>附加分总额：</span><el-input placeholder="请输入附加分总额" style="width: 180px"></el-input>
        </div>
        <div class="input_box"><el-button icon="el-icon-search" class="search_btn"></el-button></div>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column fixed prop="id" label="用户id" width="120" />
        <el-table-column prop="username" label="用户姓名" width="120" />
        <el-table-column prop="commintyName" label="社区名称" />
        <el-table-column prop="level" label="赛区名称" />
        <el-table-column prop="remarker" label="附加分" />
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageSize
      layout="total,sizes, prev, pager, next" :total=total>
    </new-pagination>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      tableData: [
        { id: 1 }
      ],
      dkTypes: [
        {
          value: '膳食',
          label: '膳食'
        },
        {
          value: '运动',
          label: '运动'
        },
        {
          value: '监测',
          label: '监测'
        },
        {
          value: '用药',
          label: '用药'
        },
        {
          value: '考试',
          label: '考试'
        },
      ],
      value: [],
      startDate: '',
      endDate: "",
      pageSize: 10,
      total: 50,
    }
  },
  methods: {
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      console.log(val)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      console.log(val)
    },
  }
}
</script>

<style scoped></style>
